<template src="./ChangePasswordUserController.html"></template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";
import loginService from "@/services/Login";
import { songs } from "@/utils/constants";
import { nodeInstance, phpInstance } from "@/plugins/axios";
import { roleName } from "@/utils/constants";

export default {
  name: "changePasswordUserController",
  components: { ArrobaMedellinLogo },
  data() {
    return {
      songs,
      password: "",
      password_verify: "",
      loginError: false,
      roleName,
      loading: false,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      containsPasswordRequirement: (value) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value),
    },
    password_verify: {
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    goToHome() {
      const ram = navigator.deviceMemory;
      if (ram === 8) {
        this.$router.push("/");
      } else if (
        navigator.platform.indexOf("Mac") === 0 ||
        navigator.platform === "iPhone"
      ) {
        this.$router.push("/home");
      } else {
        this.$router.push("/home");
      }
    },
    async changePassword() {
      try {
        this.loading = true;
        this.$store.dispatch("fetchLoading", true);
        await loginService.passwordRecoveryUserController({
          password: this.password,
          password_verify: this.password_verify,
        });
        const userRole = this.$store.getters.getUserRole;
        const infoUser = await loginService.getUserAuthByRol({
          userId: JSON.stringify(userRole[0].id),
          token: this.$store.getters.getTokenControllerPhp,
        });
        this.$store.dispatch("fetchUser", infoUser.user);
        this.$store.dispatch("fetchJwt", infoUser.tokenNode);
        this.$store.dispatch("fetchToken", infoUser.tokenPhp);
        nodeInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${infoUser.tokenNode}`;
        phpInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${infoUser.tokenPhp}`;
        const flagUser = infoUser.user.arrobaId;
        if (flagUser) {
          this.$store.dispatch("fetchAuth", true);
          this.goToHome();
        } else {
          if (
            infoUser.role.name === this.roleName.TEACHER ||
            infoUser.role.name === this.roleName.STUDENT
          ) {
            this.$store.dispatch("fetchAuth", false);
            this.$store.dispatch("fetchUserEmail", infoUser.user.document);
            this.$router.push("/nuevousuario");
          } else {
            this.$store.dispatch("fetchAuth", true);
            this.goToHome();
          }
        }
        this.loading = false;
        this.$store.dispatch("fetchLoading", false);
        this.goToHome();
      } catch (e) {
        console.log(e);
        this.songs.ALERT.play();
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
  },
};
</script>

<style lang="scss" src="./ChangePasswordUserController.scss" scoped></style>
